(function() {
    'use strict';
}());

app.service('AlgoliaService', [
    'algolia',
    'PricingService',
    'UserService',
    'ALGOLIA_APP',
    'ALGOLIA_API_KEY',
    'ALGOLIA_SR_APP',
    'ALGOLIA_SR_API_KEY',
    function(algolia, PricingService, UserService, ALGOLIA_APP, ALGOLIA_API_KEY, ALGOLIA_SR_APP, ALGOLIA_SR_API_KEY) {
        var self = this;
        var client = algolia.Client(ALGOLIA_APP, ALGOLIA_API_KEY);

        var indexEnvironment = 'dev';
        try {
            indexEnvironment = angular.element('body').injector().get('ALGOLIA_ENVIRONMENT');
        } catch (e) {}
        var productEnvironment = 'stibo';

        var productIndex = client.initIndex('ecomm_' + productEnvironment + '_products');
        var productSuggestionsIndex = client.initIndex('ecomm_' + productEnvironment + '_products_suggestions');
        var productIndexOrderByCostAsc = client.initIndex('ecomm_' + productEnvironment + '_products_orderby_cost_asc');
        var productIndexOrderByCostDesc = client.initIndex('ecomm_' + productEnvironment + '_products_orderby_cost_desc');
        var taxonomyIndex = client.initIndex('ecomm_' + productEnvironment + '_taxonomy');
        var customerIndex = client.initIndex('customer_list_' + indexEnvironment);
        var vendorIndex = client.initIndex('vendor_list_' + indexEnvironment);

        self.cribSearch = function(term, dataSource, params) {
            var algoliaApp = dataSource === 'custom' ? ALGOLIA_APP : ALGOLIA_SR_APP,
                algoliaApiKey = dataSource === 'custom' ? ALGOLIA_API_KEY : ALGOLIA_SR_API_KEY,
                algoliaIndex = dataSource === 'custom' ? productIndex.indexName :
                    indexEnvironment === 'production' ? 'live_customer_search_prod' : 'staging_customer_search_prod'
            var srClient = algolia.Client(algoliaApp, algoliaApiKey);
            var srIndex = srClient.initIndex(algoliaIndex);
            return srIndex.search(term, params);
        };

        self.getProductBySlug = function(slug, params) {
            var catalogFilters = [];
            angular.forEach(UserService.getUserCatalogs(), function(catalog) {
                catalogFilters.push('catalogs:' + catalog);
            });

            if (!params) {
                params = {
                    term: '',
                    hitsPerPage: 1000,
                    facetFilters: [catalogFilters, 'active:true']
                };
            }

            if (!params.facetFilters) {
                params.facetFilters = [];
            }

            params.facetFilters.push('slug:' + slug);

            return self.searchProducts(params).then(function(data) {
                if (data && data.hits && data.hits.length > 0) {
                    var product = data.hits[0];
                    angular.forEach(product.items, function(item) {
                        var newSpecs = [];
                        angular.forEach(item.specifications, function(spec, key) {
                            var title = product.specifications[key];
                            newSpecs.push({
                                title: title,
                                value: spec
                            });
                        });
                        item.specifications = newSpecs;
                    });
                    return product;
                } else {
                    return null;
                }
            });
        };

        self.setPricing = function(product, frc) {
            return PricingService.getPricing(product.items).then(function (prices) {
                angular.forEach(product.items, function (item) {
                    item.unavailable = false;
                    if (prices) {
                        angular.forEach(prices, function (priceList) {
                            angular.forEach(priceList, function (price){
                                if (item.erp == price.erp) {
                                    item.quantity = 0;
                                    item.quantity_available = price.quantity_available;
                                    item.price = price.price;
                                    item.uom = price.uom;
                                    item.pricing = price.pricing;

                                    if (!item.hasOwnProperty("units") || !item.hasOwnProperty("unit_type")) {
                                        angular.forEach(item.item_specifications, function (spec) {
                                            var split = spec.split('|');
                                            if (split[0] === "Units") {
                                                item.units = parseFloat(split[1]);
                                                item.quantity = 0;
                                            }
                                            if (split[0] === "Unit Type") {
                                                item.unit_type = split[1];
                                            }
                                        });
                                    }

                                    if ((frc && item.units === undefined) || item.price === undefined) {
                                        item.unavailable = true;
                                    }

                                    return false;
                                }
                            });
                        });
                    }
                });

                return product;
            });
        };

        self.searchSuggestions = function(params) {
            var term = params.term ? params.term : '';
            delete params.term;
            if (!params.facetFilters) {
                params.facetFilters = [];
            }

            applyCatalogFilters(params);

            params.facetFilters.push('active:true');

            return productSuggestionsIndex.search(term, params);
        };

        self.searchItems = function(params) {
            var index = productIndex;
            if (params.orderBy) {
                switch(params.orderBy) {
                    case 'orderby_cost_asc':
                        index = productIndexOrderByCostAsc;
                        break;
                    case 'orderby_cost_desc':
                        index = productIndexOrderByCostDesc;
                        break;
                    default:
                        console.error('Invalid order by index for search.');
                }
                delete params.orderBy;
            }

            var term = params.term ? params.term : '';
            delete params.term;
            if (!params.facetFilters) {
                params.facetFilters = [];
            }

            applyCatalogFilters(params);

            params.facetFilters.push('active:true');
            return index.search(term, params);
        };

        self.searchProducts = function(params) {

            return self.searchItems(params).then(function(data) {
                if (data && data.hits && data.hits.length) {
                    var products = {};
                    var duplicates = [];
                    data.hits.forEach(function(hit, index) {
                        // do not display the same erp multiple times within product - can occur with multiple catalogs
                        var dupKey = hit.product_id + '|' + hit.erp;
                        if (duplicates.indexOf(dupKey) !== -1) {
                            data.hits.splice(index, 1);
                            data.nbHits--;
                        } else {
                            duplicates.push(dupKey);

                            var part = hit.slug;
                            if (!hit.description) {
                                hit.description = hit.vendor.name + " " + hit.heading;
                            }
                            if (products[part]) {
                                hit.specifications = hit.specification_values;
                                products[part].items.push(hit);
                            } else {
                                var newHit = JSON.parse(JSON.stringify(hit));
                                newHit.specifications = newHit.specification_values;
                                hit.items = [newHit];
                                products[part] = hit;
                            }
                        }
                    });

                    data.hits = [];
                    angular.forEach(products, function(product) {
                        data.hits.push(product);
                    });
                }

                return data;
            });
        };

        self.getItemUnits = function(cart) {
            var params = {
                hitsPerPage: 1000,
                facetFilters: []
            };

            var erpFilters = [];
            angular.forEach(cart.cart_lines, function (item) {
                if (item.erp) {
                    erpFilters.push('erp:'+item.erp);
                }
            });

            var units = {};
            if (erpFilters.length > 0) {
                params.facetFilters.push(erpFilters);
                applyCatalogFilters(params);

                return productIndex.search('', params).then(function(data) {
                    if (data && data.hits && data.hits.length) {
                        angular.forEach(data.hits, function(hit) {

                            angular.forEach(hit.item_specifications, function (spec) {
                                var split = spec.split('|');
                                if (split[0] === "Units") {
                                    units[hit.erp] = parseFloat(split[1]);
                                }
                            });
                        });
                    }
                    return units;
                });
            }
        };

        self.searchTaxonomy = function(params, taxonomyResults) {
            var term = params.term ? params.term : '';
            delete params.term;

            applyCatalogFilters(params);
            if (undefined === taxonomyResults) {
                return taxonomyIndex.search(term, params);
            }
            // Store results in taxonomyResults (if provided) to eliminate duplicate calls to Algolia
            var key = term + JSON.stringify(params);
            if (!taxonomyResults[key]) {
                taxonomyResults[key] = taxonomyIndex.search(term, params);
            }
            return taxonomyResults[key];
        };

        self.browseTaxonomy = function(params) {
            return new Promise(function(resolve, reject){
                applyCatalogFilters(params);
                var hits = [];
                var browser = taxonomyIndex.browseAll(params);
                browser.on('result', function(content) {
                    hits = hits.concat(content.hits);
                });

                browser.on('end', function() {
                    resolve(hits);
                });

                browser.on('error', function(err) {
                    reject(err);
                });
            });
        };

        function applyCatalogFilters(params) {
            if (!params.facetFilters) {
                params.facetFilters = [];
            }
            var catalogFilters = [];
            angular.forEach(UserService.getUserCatalogs(), function(catalog) {
                catalogFilters.push('catalogs:' + catalog);
            });
            if (catalogFilters.length === 1) {
                catalogFilters = catalogFilters[0];
            }
            params.facetFilters.push(catalogFilters);
        }

        self.getCustomer = function(custNo) {
            return customerIndex.search('', {facetFilters:['customerNumber:'+parseInt(custNo)]})
                .then(function(data) {
                    if (data && data.hits && data.hits.length) {
                        return data.hits[0];
                    }
                });
        };

        self.searchCustomers = function(term, params) {
            if (!params) {
                params = {};
            }

            if (!params.hitsPerPage) {
                params.hitsPerPage = 1000;
            }
            return customerIndex.search(term, params);
        };

        self.searchVendors = function(term, params) {
            if (!params) {
                params = {};
            }

            if (!params.hitsPerPage) {
                params.hitsPerPage = 1000;
            }
            return vendorIndex.search(term, params);
        };

        self.cleanFilter = function(string) {
            // escape all double quotes that have not already been escaped
            return string.replace(/\\([\s\S])|(")/g, "\\$1$2");
        };
    }
]);
