(function () {
    'use strict';
}());

angular.module('app')
    .constant('MAINTENANCE', true)
    .constant('DOMAIN', 'boringsmith')
    .constant('API_URL', 'https://ecomm-api.vallen.com')
    .constant('LIVE', true)
    .constant('GOLIVE_LOCK', false)
    .constant('REDIRECT', false)
    .constant('S3_RESOURCES', 'https://d1rf5k8xr5m41z.cloudfront.net')
    .constant('PRODUCT_RESOURCES', 'https://d1rf5k8xr5m41z.cloudfront.net') //S3 staging-ecomm-resources, same as S3 Resources
    //.constant('PRODUCT_RESOURCES', 'https://d2k8mcbjflblog.cloudfront.net') //images parsed in ecomm-api.vallen.com/images
    .constant('LEGACY_IDG_FLAG', '3fa869fefc8854ccb4058ec48e7f02a3')
    .constant('LEGACY_HNA_FLAG', 'UserName')
    .constant('LEGACY_IDG_LINK', 'https://www.idgsupply.com/')
    .constant('LEGACY_HNA_LINK', 'https://www.hagemeyerna.com/')
    .constant('NEWSLETTER_LIST_ID', 75798)
    .constant('PASSWORD_REGEX', /((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{8,50})/)
    .constant('PHONE', '800-476-4700')
    .constant('PHONE_HREF', '+18004764700')
    .constant('OPEN_BROWSE_COMPANY_NUMBER', 1)
    .constant('OPEN_BROWSE_CUSTOMER_NUMBER', 122185)
    .constant('OPEN_BROWSE_WAREHOUSE', 'DBEL')
    .constant('DEFAULT_CATALOGS', ['2014BSWebCatalog'])
    .constant('WORDPRESS_URL', 'https://d2wuh70cvefix9.cloudfront.net')
    .constant('PUNCHOUT_SRC', ['suppliersolutions.com'])
    .constant('ALGOLIA_ENVIRONMENT', 'production')
    .constant('ALGOLIA_APP', 'LCZ09P4P1R')
    .constant('ALGOLIA_API_KEY', 'a43f44a2001c896ebfc29a245e4902f6')
    .constant('ALGOLIA_SR_APP', 'LF2EHKNLX1')
    .constant('ALGOLIA_SR_API_KEY', 'c34bfda131f3bd804d1058228fc6d7d2')
    .constant('CENPOS_MERCHANT_ID', '400004325')
    .constant('IPSTACK_ACCESS_KEY', '4199bf66650650dcf068230447791e77')
    .constant('INTL_SITES', { CA: 'https://www.Vallen.ca', MX: 'https://www.Vallen.com.mx' });
